console.log("Vite ⚡️ Rails");

import "~vue-calendar-3/dist/style.css";
import "~/styles/application.css";
import "floating-vue/dist/style.css";

// Import createApp function from Vue
import { createApp } from "vue/dist/vue.esm-bundler";
import $ from "jquery";

import Rails from "@rails/ujs";

// Old import
import {
  ServicesCreatePrice,
  syncPayOutSelection,
  syncBookingServiceSelection,
} from "@/old_controller";

// Import Plugins
import dayjsPlugin from "@/helpers/dayjs";

import { Calendar } from "vue-calendar-3";
import Editor from "@tinymce/tinymce-vue";
import FloatingVue from "floating-vue";

// Import components
import BaseLogo from "@/components/BaseLogo.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import BaseCallback from "@/components/BaseCallback.vue";
import BaseActionList from "@/components/BaseActionList.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";

// Import views
import BaseHeader from "@/views/BaseHeader.vue";
import BookingActionsQuote from "@/views/Bookings/ActionsQuote.vue";
import BookingQuotesCompanyForm from "@/views/Bookings/Quotes/Company/New.vue";
import BookingQuotesIndividualForm from "@/views/Bookings/Quotes/Individual/New.vue";
import BookingServicesForm from "@/views/Bookings/Services/Form.vue";
import BookingServicesShow from "@/views/Bookings/Services/BookingServicesShow.vue";
import BookingsFilter from "@/views/Bookings/BookingsFilter.vue";
import BookingShow from "@/views/Bookings/Show.vue";
import ContractNewForm from "@/views/Contracts/New/Form.vue";
import ContractTailorSelect from "@/views/Contracts/Tailors/ContractTailorSelect.vue";
import ClientItinerary from "@/views/Contracts/ClientItinerary/ClientItinerary.vue";
import PayInsEdit from "@/views/PayIns/Edit.vue";
import PayOutsNew from "@/views/PayOuts/New.vue";
import ServicesFilter from "@/views/Services/ServicesFilter.vue";
import ThirdPartiesCheckbox from "@/views/ThirdParties/ThirdPartiesCheckbox.vue";
import ThirdPartiesClientForm from "@/views/ThirdParties/Client/Edit.vue";
import ThirdPartiesFilter from "@/views/ThirdParties/ThirdPartiesFilter.vue";
import ThirdPartiesProviderForm from "@/views/ThirdParties/Provider/Edit.vue";
import ThirdPartiesServicesForm from "@/views/ThirdParties/Services/Form.vue";
import PayInIndex from "@/views/PayIns/Index.vue";

// Import layouts
import TheSidebar from "@/layouts/TheSidebar.vue";

// Create App
const app = createApp();

// Libraries
app.component("Calendar", Calendar);
app.component("Editor", Editor);
app.use(FloatingVue);

// Register each global components (like base-logo)
app.component("BaseActionList", BaseActionList);
app.component("BaseCallback", BaseCallback);
app.component("BaseCheckbox", BaseCheckbox);
app.component("BaseIcon", BaseIcon);
app.component("BaseLogo", BaseLogo);

// Register each views
app.component("BaseHeader", BaseHeader);
app.component("BookingActionsQuote", BookingActionsQuote);
app.component("BookingQuotesCompanyForm", BookingQuotesCompanyForm);
app.component("BookingQuotesIndividualForm", BookingQuotesIndividualForm);
app.component("BookingServicesForm", BookingServicesForm);
app.component("BookingServicesShow", BookingServicesShow);
app.component("BookingShow", BookingShow);
app.component("BookingsFilter", BookingsFilter);
app.component("ClientItinerary", ClientItinerary);
app.component("ContractNewForm", ContractNewForm);
app.component("ContractTailorSelect", ContractTailorSelect);
app.component("PayInsEdit", PayInsEdit);
app.component("PayOutsNew", PayOutsNew);
app.component("ServicesFilter", ServicesFilter);
app.component("ThirdPartiesCheckbox", ThirdPartiesCheckbox);
app.component("ThirdPartiesClientForm", ThirdPartiesClientForm);
app.component("ThirdPartiesFilter", ThirdPartiesFilter);
app.component("ThirdPartiesProviderForm", ThirdPartiesProviderForm);
app.component("ThirdPartiesServicesForm", ThirdPartiesServicesForm);
app.component("PayInsIndex", PayInIndex);

// Layouts
app.component("TheSidebar", TheSidebar);

// Register Plugins
app.use(dayjsPlugin);

// Mount App in DOM
app.mount("#app");

/////////
// OLD //
/////////

// /views/bank_exports/new Disable submit button if no checkbox is checked
function toggleSubmitButtonState() {
  const $payOutSelection = $(".pay-out-selection");
  const $bankExportSubmit = $("#bankExportSubmit");

  function isAnyCheckboxChecked() {
    return $payOutSelection.filter(":checked").length > 0;
  }

  function updateButtonState() {
    $bankExportSubmit.prop("disabled", !isAnyCheckboxChecked());
  }

  $payOutSelection.on("change", updateButtonState);
  updateButtonState();
}

if ($("#bank_export_new")) {
  toggleSubmitButtonState();
  syncPayOutSelection();
}

function handleSelectionChange(selector, paramName) {
  $(selector).on("change", function () {
    const href = new URL(window.location.href);
    href.searchParams.set(paramName, $(this).val());
    window.location = href.toString();
  });
}

handleSelectionChange("#category-selection", "category_id");
handleSelectionChange("#destination-selection", "destination_id");
handleSelectionChange("#subsidiary-selection", "subsidiary_id");

$("#notice-alert").delay(5000).fadeOut("slow");

window.handleRemoteNotice = function (html) {
  $("#notice-alert").remove();
  $("#app").prepend(html);
  $("#notice-alert").delay(5000).fadeOut("slow");
};

// Toggle table row
$(".rowToClick").click(function () {
  if ($(this).nextUntil(".rowToClick").hasClass("collapse-open")) {
    $(this).nextUntil(".rowToClick").removeClass("collapse-open");
  } else {
    $(this).nextUntil(".rowToClick").addClass("collapse-open");
  }
});

const createButton = document.getElementById("addLink");
if (createButton) {
  createButton.addEventListener("click", (e) => {
    e.preventDefault();
    const lastId =
      document.querySelector("#fieldsetContainer").lastElementChild.id;

    const newId = parseInt(lastId, 10) + 1;

    const newFieldset = document
      .querySelector('[id="0"]')
      .outerHTML.replace(/0/g, newId);

    document
      .querySelector("#fieldsetContainer")
      .insertAdjacentHTML("beforeend", newFieldset);
  });
}

// /views/third_parties/services/prices/form
if ($("#third_parties_services_prices_form")) {
  ServicesCreatePrice();
}

// /views/bookings/show
if ($("#booking-services")) {
  $(".dropdown_button").click(function () {
    $(this).next(".dropdown_content").toggleClass("dropdown_content--open");
  });
}

if ($("#all_retrocommission")) {
  syncBookingServiceSelection();
}

Rails.start();
