<script setup lang="ts">
import { computed, reactive, ref } from "vue";

import BaseMultiselect from "@/components/BaseMultiselect.vue";
import type { Booking, Contract, Contact, TInputFields } from "@/types";

type dataCompany = {
  clientCompanyRepresentativeFirstname: string | undefined;
  clientCompanyRepresentativeLastname: string | undefined;
  clientCompanyRepresentativePhone: string | undefined;
  clientCompanyRepresentativeEmail: string | undefined;
  clientCompanyName: string | undefined;
  clientCompanyAddress: string | undefined;
  clientCompanyZipcode: string | undefined;
  clientCompanyCity: string | undefined;
  clientCompanyCountry: string | undefined;
  clientCompanyVatNumber: number | null | undefined;
  clientCompanyIdentifier: string | undefined;
};

const token = ref<string | null>(
  document.getElementsByName("csrf-token")[0].getAttribute("content"),
);

const booking = ref<Booking | undefined>(window.gon?.booking);
const countries = ref<object>(window.gon?.countries);
const currentQuote = ref<Contact>(window.gon?.current_quote);
const contract = ref<Contract>(window.gon?.contract);

const data = reactive<dataCompany>({
  clientCompanyRepresentativeFirstname:
    currentQuote.value?.client_company_representative_firstname ||
    contract.value?.client_company_representative_firstname,
  clientCompanyRepresentativeLastname:
    currentQuote.value?.client_company_representative_lastname ||
    contract.value?.client_company_representative_lastname,
  clientCompanyRepresentativePhone:
    currentQuote.value?.client_company_representative_phone ||
    contract.value?.client_company_representative_phone,
  clientCompanyRepresentativeEmail:
    currentQuote.value?.client_company_representative_email ||
    contract.value?.client_company_representative_email,
  clientCompanyName:
    currentQuote.value?.client_company_name ||
    contract.value?.client_company_name,
  clientCompanyAddress:
    currentQuote.value?.client_company_address ||
    contract.value?.client_company_address,
  clientCompanyZipcode:
    currentQuote.value?.client_company_zip_code ||
    contract.value?.client_company_zip_code,
  clientCompanyCity:
    currentQuote.value?.client_company_city ||
    contract.value?.client_company_city,
  clientCompanyCountry:
    currentQuote.value?.client_company_country ||
    contract.value?.client_company_country,
  clientCompanyVatNumber:
    currentQuote.value?.client_company_vat_number ||
    contract.value?.client_company_vat_number,
  clientCompanyIdentifier:
    currentQuote.value?.client_company_identifier ||
    contract.value?.client_company_identifier,
});

const action = computed(() => {
  return `/bookings/${booking.value?.id}/quotes`;
});

const disabledButton = computed(() => {
  return !(
    data.clientCompanyRepresentativeFirstname &&
    data.clientCompanyRepresentativeLastname &&
    data.clientCompanyRepresentativePhone &&
    data.clientCompanyRepresentativeEmail &&
    data.clientCompanyName &&
    data.clientCompanyAddress &&
    data.clientCompanyZipcode &&
    data.clientCompanyCity &&
    data.clientCompanyCountry &&
    data.clientCompanyVatNumber &&
    data.clientCompanyIdentifier
  );
});

const formatCountries = computed(() => {
  return Object.entries(countries.value).map(([key, value]) => {
    return {
      value: key,
      label: value,
    };
  });
});

const inputFields = ref<TInputFields[]>([
  {
    label: "First name",
    model: "clientCompanyRepresentativeFirstname",
    name: "booking_quote[client_company_representative_firstname]",
    id: "booking_quote_client_company_representative_firstname",
  },
  {
    label: "Last name",
    model: "clientCompanyRepresentativeLastname",
    name: "booking_quote[client_company_representative_lastname]",
    id: "booking_quote_client_company_representative_lastname",
  },
  {
    label: "Phone number",
    model: "clientCompanyRepresentativePhone",
    name: "booking_quote[client_company_representative_phone]",
    id: "booking_quote_client_company_representative_phone",
  },
  {
    label: "Email",
    model: "clientCompanyRepresentativeEmail",
    name: "booking_quote[client_company_representative_email]",
    id: "booking_quote_client_company_representative_email",
    rules: {
      type: "email",
      required: true,
    },
  },
  {
    label: "Company name",
    model: "clientCompanyName",
    name: "booking_quote[client_company_name]",
    id: "booking_quote_client_company_name",
  },
  {
    label: "Company VAT number",
    model: "clientCompanyVatNumber",
    name: "booking_quote[client_company_vat_number]",
    id: "booking_quote_client_company_vat_number",
  },
  {
    label: "Company identifier (CIF / SIREN / SIRET)",
    model: "clientCompanyIdentifier",
    name: "booking_quote[client_company_identifier]",
    id: "booking_quote_client_company_identifier",
  },
  {
    label: "Address",
    model: "clientCompanyAddress",
    name: "booking_quote[client_company_address]",
    id: "booking_quote_client_company_address",
  },
  {
    label: "Zip code",
    model: "clientCompanyZipcode",
    name: "booking_quote[client_company_zip_code]",
    id: "booking_quote_client_company_zip_code",
  },
  {
    label: "City",
    model: "clientCompanyCity",
    name: "booking_quote[client_company_city]",
    id: "booking_quote_client_company_city",
  },
]);
</script>

<template>
  <form
    :action="action"
    class="mt-4"
    enctype="multipart/form-data"
    accept-charset="UTF-8"
    method="post"
  >
    <input
      type="hidden"
      name="booking_quote[kind]"
      value="company"
      autocomplete="off"
    />
    <input
      :value="token"
      type="hidden"
      name="authenticity_token"
      autocomplete="off"
    />

    <div
      v-for="field in inputFields"
      :key="field.id"
      :class="`mb-4 string required booking_quote_${field.model}`"
    >
      <h3
        v-if="field.label === 'Company name'"
        class="py-3 text-xl leading-7 text-gray-900 font-bold sm:text-xl sm:truncate"
      >
        Company information
      </h3>

      <label
        :for="field.id"
        class="block string required text-sm font-medium text-gray-600"
      >
        {{ field.label }} <abbr title="required">*</abbr>
      </label>
      <input
        v-model="data[field.model as keyof dataCompany]"
        v-bind="field.rules ?? { type: 'text', required: true }"
        :name="field.name"
        :id="field.id"
        class="appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string required invalid:border-red-500"
      />
    </div>

    <div class="mb-4 email third_party_country">
      <label
        class="block email text-sm font-medium text-gray-600"
        for="third_party_country"
      >
        Country <abbr title="required">*</abbr>
      </label>
      <BaseMultiselect
        v-model="data.clientCompanyCountry"
        :options="formatCountries"
        :searchable="true"
        name="booking_quote[client_company_country]"
        id="booking_quote_client_company_country"
        attribute="client_company_country"
        model="booking_quote"
      />
    </div>

    <button
      type="submit"
      :disabled="disabledButton"
      class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 mx-2 disabled:bg-gray-100 disabled:text-gray-400 cursor-pointer"
    >
      Validate & create quote on Zoho Books
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="ml-2"
      >
        <path
          d="M4 13.334L12 13.334"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.99984 2.66602V10.666M7.99984 10.666L10.3332 8.33268M7.99984 10.666L5.6665 8.33268"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </form>
</template>
