<script setup lang="ts">
import { computed, reactive, ref, onMounted } from "vue";

import BaseMultiselect from "@/components/BaseMultiselect.vue";

import type {
  Destination,
  Link,
  Service,
  SubCategory,
  ThirdParty,
} from "@/types";
import { Category } from "@/types/ThirdParty";

type TValidation = {
  [key: number]: boolean;
};

type TData = {
  comment: string;
  description: {
    en: string;
    fr: string;
  };
  duration: string;
  serviceHasBookingServices: boolean;
  invoicingType: string;
  name: string;
  onSitePayment: boolean;
  pricingType: string;
  selectedSubCategory: null | number;
};

const token = ref<string | null>(
  document.getElementsByName("csrf-token")[0].getAttribute("content"),
);

const destinations = ref<Destination[]>(window.gon?.destinations);
const links = ref<Link[]>(window.gon?.links || []);
const service = ref<Service>(window.gon?.service || []);
const serviceHasBookingServices =
  ref<boolean>(window.gon?.serviceHasBookingServices) ?? false;
const subCategories = ref<SubCategory[]>(window.gon.subCategories);
const thirdParty = ref<ThirdParty>(window.gon?.thirdParty);
const serviceDestination = ref<number[]>(window.gon?.serviceDestination || []);
const serviceSubCategory = ref<number | null>(
  window.gon?.serviceSubCategory || null,
);
const validation = ref<TValidation>({});

const data = reactive<TData>({
  comment: service.value?.comment || "",
  description: {
    en: service.value?.description?.en || "",
    fr: service.value?.description?.fr || "",
  },
  duration: service.value?.duration || "",
  serviceHasBookingServices: serviceHasBookingServices.value || false,
  invoicingType: thirdParty.value?.invoicing_type || "",
  name: service.value?.name || "",
  onSitePayment: service.value?.on_site_payment || false,
  pricingType: service.value?.pricing_type || "",
  selectedSubCategory: null,
});

const isRetroComType = computed(() => {
  return (
    data.invoicingType === "retro_com_end_of_service" ||
    data.invoicingType === "retro_com_end_of_season"
  );
});

const isUpdate = computed(() => {
  return service.value?.id;
});
const buttonWording = computed(() => {
  if (isUpdate.value) return "Update service";

  return "Create service";
});
const action = computed(() => {
  if (isUpdate.value)
    return `/third_parties/${thirdParty.value.id}/services/${service.value.id}`;

  return `/third_parties/${thirdParty.value.id}/services`;
});
const formatDestinations = computed(() => {
  return destinations.value.map((desti: Destination) => {
    return {
      value: desti.id,
      label: desti.name.en,
    };
  });
});
const formatSubCategories = computed(() => {
  return subCategories.value.map((category: Category) => {
    return {
      label: category.name,
      value: category.id,
    };
  });
});

const disabledButton = computed(() => {
  const isPricingType =
    (data.pricingType === "duration" && Boolean(data.duration)) ||
    data.pricingType === "per_unit";
  const validateLinksUrl = Object.values(validation.value)?.every(
    (valid) => valid,
  );
  const valideLinksName = links.value.every((link) => link.name);

  if (
    isPricingType &&
    data.name &&
    serviceSubCategory.value &&
    serviceDestination.value.length &&
    validateLinksUrl &&
    valideLinksName
  ) {
    return false;
  }

  return true;
});

const validateLinksUrl = (index: number, url: string) => {
  try {
    new URL(url);

    validation.value[index] = true;
  } catch (err) {
    validation.value[index] = false;
  }
};

const addLinks = () => {
  links.value.push({
    name: "",
    url: "",
    id: null,
  });
};

const constructLinksErrors = () => {
  links.value.forEach((link, index) => {
    validateLinksUrl(index, link.url);
  });
};

onMounted(() => {
  constructLinksErrors();
});
</script>

<template>
  <div class="my-8">
    <form
      class="mt-8"
      id="new_service"
      novalidate
      :action="action"
      accept-charset="UTF-8"
      method="post"
    >
      <input
        type="hidden"
        name="authenticity_token"
        autocomplete="off"
        :value="token"
      />
      <input
        v-if="isUpdate"
        type="hidden"
        name="_method"
        value="patch"
        autocomplete="off"
      />

      <div class="flex mb-4">
        <div class="w-[40%]">
          <h1 class="text-xl font-bold">General information</h1>
          <span class="text-gray-600">
            Complete all general information about the service.
          </span>
        </div>
        <div class="w-[60%]">
          <div class="mb-4 string required service_name">
            <label
              class="block string required text-sm font-medium text-gray-600"
              for="service_name"
            >
              Service name <abbr title="required">*</abbr>
            </label>
            <input
              v-model="data.name"
              class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string required"
              type="text"
              name="service[name]"
              id="service_name"
            />
          </div>
          <div class="mb-4 select required service_category">
            <label
              class="block select required text-sm font-medium text-gray-600"
              for="service_category_id"
            >
              Sub-Category <abbr title="required">*</abbr>
            </label>
            <BaseMultiselect
              v-model="serviceSubCategory"
              :options="formatSubCategories"
              :searchable="true"
              attribute="category_id"
              model="service"
            />
          </div>
          <div class="mb-4 select optional service_destinations">
            <label
              class="block select optional text-sm font-medium text-gray-600"
              for="service_destination_ids"
            >
              Destinations <abbr title="required">*</abbr>
            </label>
            <BaseMultiselect
              v-model="serviceDestination"
              :options="formatDestinations"
              :searchable="true"
              mode="tags"
              attribute="destination_ids"
              model="service"
            />
          </div>
        </div>
      </div>

      <div class="flex mb-4">
        <div class="w-[40%]">
          <h1 class="text-xl font-bold">Service description</h1>
          <span class="text-gray-600">
            Add detailed description of the service.
          </span>
        </div>
        <div class="w-[60%]">
          <div class="grid grid-cols-2 gap-6">
            <div>
              <div class="mb-4 text required service_description_en">
                <label
                  class="block text required text-sm font-medium text-gray-600"
                  for="service_description_en"
                >
                  English description <abbr title="required">*</abbr>
                </label>
                <textarea
                  v-model="data.description.en"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out text required"
                  name="service[description][en]"
                  rows="6"
                  id="service_description_en"
                />
              </div>
            </div>
            <div>
              <div class="mb-4 text required service_description_fr">
                <label
                  class="block text required text-sm font-medium text-gray-600"
                  for="service_description_fr"
                >
                  French description <abbr title="required">*</abbr>
                </label>
                <textarea
                  v-model="data.description.fr"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out text required"
                  name="service[description][fr]"
                  rows="6"
                  id="service_description_fr"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex mb-4">
        <div class="w-[40%]">
          <h1 class="text-xl font-bold">Pricing information</h1>
          <span class="text-gray-600">
            Select the pricing type of the service.
          </span>
        </div>
        <div class="w-[60%]">
          <div class="grid grid-cols-6 gap-6">
            <div class="mb-4 select required service_pricing_type">
              <label
                class="block select required text-sm font-medium text-gray-600"
                for="service_pricing_type"
              >
                Pricing type <abbr title="required">*</abbr>
              </label>
              <select
                v-model="data.pricingType"
                class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out select required w-40"
                name="service[pricing_type]"
                id="service_pricing_type"
              >
                <option label="" value=""></option>
                <option value="per_unit">per_unit</option>
                <option value="duration">duration</option>
              </select>
            </div>
            <div
              class="mb-4 select optional service_duration"
              v-if="data.pricingType === 'duration'"
            >
              <label
                class="block select optional text-sm font-medium text-gray-600"
                for="service_duration"
              >
                Duration <abbr title="required">*</abbr>
              </label>
              <select
                v-model="data.duration"
                class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out select optional w-40"
                name="service[duration]"
                id="service_duration"
              >
                <option label="" value=""></option>
                <option value="number_of_hours">number_of_hours</option>
                <option value="number_of_days">number_of_days</option>
                <option value="number_of_weeks">number_of_weeks</option>
              </select>
            </div>
          </div>
          <div v-if="isRetroComType" class="mb-4">
            <input
              type="hidden"
              name="service[on_site_payment]"
              value="false"
            />
            <input
              v-model="data.onSitePayment"
              type="checkbox"
              name="service[on_site_payment]"
              id="service_on_site_payment"
              :disabled="data.serviceHasBookingServices"
            />
            <label
              class="ml-2 text-sm font-medium text-gray-600"
              for="service_on_site_payment"
            >
              Customers will have to pay on-site directly to the third-party.
            </label>
          </div>
        </div>
      </div>

      <div class="flex mb-4">
        <div class="w-[40%]">
          <h1 class="text-xl font-bold">Additional information</h1>
          <span class="text-gray-600">
            Select the pricing type of the service.
          </span>
        </div>
        <div class="w-[60%]">
          <div class="mb-4 text optional service_comment">
            <label
              class="block text optional text-sm font-medium text-gray-600"
              for="service_comment"
            >
              Comment
            </label>
            <textarea
              v-model="data.comment"
              class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out text optional"
              name="service[comment]"
              id="service_comment"
            />
          </div>
          <div id="fieldsetContainer">
            <fieldset
              :id="link.id?.toString()"
              v-for="(link, index) in links"
              :key="index"
            >
              <div class="grid grid-cols-3 gap-6">
                <div>
                  <div class="mb-4 string optional">
                    <label
                      class="block string optional text-sm font-medium text-gray-600"
                      :for="`service_links_attributes_${index}_name`"
                    >
                      Name of link
                    </label>
                    <input
                      v-model="link.name"
                      class="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string optional"
                      type="text"
                      :name="`service[links_attributes][${index}][name]`"
                      :id="`service_links_attributes_${index}_name`"
                    />
                  </div>
                </div>
                <div class="col-span-2">
                  <div class="mb-4 url optional">
                    <label
                      class="block url optional text-sm font-medium text-gray-600"
                      :for="`service_links_attributes_${index}_url`"
                    >
                      External link
                    </label>

                    <input
                      v-model="link.url"
                      :class="[
                        'shadow appearance-none border rounded w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 text-gray-400 leading-6 transition-colors duration-200 ease-in-out string url optional',
                        {
                          'focus:border-red-300 border-red-300':
                            index in validation && validation[index] === false,
                          'focus:border-green-300 border-green-300':
                            index in validation && validation[index] === true,
                        },
                      ]"
                      type="url"
                      :name="`service[links_attributes][${index}][url]`"
                      :id="`service_links_attributes_${index}_url`"
                      @input="validateLinksUrl(index, link.url)"
                    />
                  </div>
                </div>
              </div>
              <input
                autocomplete="off"
                type="hidden"
                :value="link.id"
                :name="`service[links_attributes][${index}][id]`"
                :id="`service_links_attributes_${index}_id`"
              />
            </fieldset>
          </div>
          <button
            class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 my-8 mr-4"
            id="addLink"
            type="button"
            @click="addLinks"
          >
            {{ links.length ? "Add another" : "Add a" }} link
          </button>
        </div>
      </div>

      <button
        name="commit"
        class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-pointer disabled:bg-gray-100 disabled:text-gray-400"
        :disabled="disabledButton"
      >
        {{ buttonWording }}
      </button>
    </form>
  </div>
</template>
